exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-job-details-backend-developer-fulltime-tsx": () => import("./../../../src/pages/jobDetails/backendDeveloperFulltime.tsx" /* webpackChunkName: "component---src-pages-job-details-backend-developer-fulltime-tsx" */),
  "component---src-pages-job-details-backend-developer-student-tsx": () => import("./../../../src/pages/jobDetails/backendDeveloperStudent.tsx" /* webpackChunkName: "component---src-pages-job-details-backend-developer-student-tsx" */),
  "component---src-pages-job-details-embedded-software-engineer-fulltime-tsx": () => import("./../../../src/pages/jobDetails/embeddedSoftwareEngineerFulltime.tsx" /* webpackChunkName: "component---src-pages-job-details-embedded-software-engineer-fulltime-tsx" */),
  "component---src-pages-job-details-embedded-software-engineer-student-tsx": () => import("./../../../src/pages/jobDetails/embeddedSoftwareEngineerStudent.tsx" /* webpackChunkName: "component---src-pages-job-details-embedded-software-engineer-student-tsx" */),
  "component---src-pages-job-details-project-manager-fulltime-tsx": () => import("./../../../src/pages/jobDetails/projectManagerFulltime.tsx" /* webpackChunkName: "component---src-pages-job-details-project-manager-fulltime-tsx" */),
  "component---src-pages-job-details-web-developer-fulltime-tsx": () => import("./../../../src/pages/jobDetails/webDeveloperFulltime.tsx" /* webpackChunkName: "component---src-pages-job-details-web-developer-fulltime-tsx" */),
  "component---src-pages-job-details-web-developer-student-tsx": () => import("./../../../src/pages/jobDetails/webDeveloperStudent.tsx" /* webpackChunkName: "component---src-pages-job-details-web-developer-student-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-project-boxagv-tsx": () => import("./../../../src/pages/project-boxagv.tsx" /* webpackChunkName: "component---src-pages-project-boxagv-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

